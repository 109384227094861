<template>
    <label :for="id" class="relative w-12 block cursor-pointer">
        <input type="checkbox" :id="id" :name="name" class="peer/status hidden"/>
        <div class="h-6 bg-gray-200 duration-200 peer-checked/status:bg-brand rounded-2xl"></div>
        <span class="w-4 h-4 block absolute bg-white rounded-2xl vertical-h left-1 duration-200 peer-checked/status:left-7"></span>
    </label>
</template>

<script>
export default {
    name : "SliderCheckbox",
    props: {
        id  : String,
        name: String,
    }
}
</script>

<style scoped>

</style>