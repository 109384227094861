<template>
    <nav class="w-16 bg-zinc-100 text-center">
        <li>
            <router-link to="/factory/apprList" class="text-xm">승인관리</router-link>
        </li>
        <li>
            <router-link to="/factory/memberList" class="text-xm">회원관리</router-link>
        </li>
    </nav>
</template>

<script>
export default {
    name: "SideHeader"
}
</script>

<style scoped>
nav{
    height: calc(100vh - 4rem);
}
</style>