<template>
    <header class="w-full flex justify-between items-center h-16 px-5 bg-zinc-100">
        <router-link to="/" class="flex items-center">
            <img src="@/assets/images/logo.svg" alt="logo" title="logo" />
            <h1 class="font-bold text-black text-base ml-2.5">올립스</h1>
        </router-link>
        <input type="search" id="" name="" />
        <ul></ul>
    </header>
</template>

<script>
export default {
    name: "HeaderView"
}
</script>

<style lang="scss" scoped>
header{
    height: 60px;
}
</style>