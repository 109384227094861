/**
 * @description 팝업 상태 관리 vuex store module
 * @author 김원명, @date 2024-02-29
 */
const popupModule = {
    namespaced : true,
    state: {
        factoryAppr             : false,
        centerAppr              : false,
        factoryService          : false,
        centerService           : false,
        factoryNormalMember     : false,
        centerNormalMember      : false,
        factoryServiceMember    : false,
        centerServiceMember     : false
    },
    mutations: {
        setStat(state, { name, stat }) 
        {
            state[name] = stat;
        }
    }
};

export default popupModule;