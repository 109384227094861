/**
 * @description 로그인 vuex store module
 * @author 김원명, @date 2024-02-28
 */
const loginModule = {
    namespaced : true,
    state: {
        jwt : null
    },
    mutations: {
        setLogin(state, jwt) {
            state.jwt = jwt;
        }
    },
    actions: {
        login({ commit }, info) 
        {
            commit('setLogin', info['jwt']);

            localStorage.setItem('info', JSON.stringify(info));
        },
        logout({ commit })
        {
            commit('setLogin', null);
            localStorage.setItem('info', JSON.stringify({ jwt : null}));
        },
        initializeState({ commit }) 
        {
            const info = localStorage.getItem('info');
            
            if (info) 
            {
                const parse = JSON.parse(info);

                commit('setLogin', parse['jwt']);
            }
        }
    }
};

export default loginModule;