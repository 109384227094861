<template>
    <MemberHeader />
    <FilterBox showFilter :filters="filters" @getFilterClick="getFilterClick"/>
    <StikyTable :columns="memberList" :rows="list" @row-click="getListClick"/>
    <FactoryMemberListPopup />
</template>

<script setup>
import MemberHeader from "@/views/common/MemberHeader.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import StikyTable from "@/components/table/StikyTable.vue";
import FactoryMemberListPopup from "@/views/factory/member/MemberListPopup.vue";

import { onMounted, computed } from "vue";
import { useStore } from 'vuex';

const store = useStore();

const filters    = computed(() => store.state.factoryNormalMember.filters);
const list       = computed(() => store.state.factoryNormalMember.list);
const memberList = [
    { key: 'id', header: '가입 이메일', class: 'text-brand w19' },
    { key: 'cntry', header: '국가', class: 'w11' },
    { key: 'area', header: '지역', class: 'w11' },
    { key: 'faNm', header: '공장명', class: 'w15' },
    { key: 'ceoNm', header: '대표자', class: 'w11' },
    { key: 'tel', header: '휴대폰', class: 'w11' },
    { key: 'joinDt', header: '가입 일시', class: 'w11' },
    { key: 'loginDt', header: '최근 로그인', class: 'w11' }
];

const getFilterClick = (val) => {
    getList(val);
}

/** 리스트 클릭 시 */
const getListClick = (data) => {
    store.commit('popup/setStat', { name : 'factoryNormalMember', stat : true });
    store.commit('factoryNormalMember/setFaCd', data['faCd']);
    store.commit('factoryNormalMember/setTabGb', 1);
    store.dispatch('factoryNormalMember/getTabInfo1');
}

/** 리스트 호출 */
const getList = (state) => {
    store.dispatch('factoryNormalMember/getList', { state : state });
}

onMounted(() => {
    store.commit('popup/setStat', { name : 'factoryNormalMember', stat : false });
    getList('Y');
});
</script>

<style scoped>

</style>