<template>
    <div class="table overflow-y-scroll scroll-none">
        <div class="min-w-[75.8rem] overflow-x-scroll xl:overflow-x-hidden">
            <table class="w-full border-collapse table-fixed border-spacing-0">
                <colgroup>
                    <col v-for="col in columns" :key="col.key" :class="col.class" />
                </colgroup>

                <thead>
                <tr class="text-xs text-left">
                    <th v-for="col in columns" :key="col.key" class="h-10 border-b border-neutral-200 px-3 [&:nth-child(1)]:pl-6">
                        {{ col.header }}
                        <FontAwesomeIcon :icon="['fas', 'sort-down']" class="align-top ml-1" />
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                    <td v-for="col in columns" :key="col.key" :class="col.class" @click="handleRowClick(row, col.class, col.key)" class="h-12 text-sm border-b border-neutral-200 px-3 [&:nth-child(1)]:pl-6">
                        {{ row[col.key] }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "StikyTable",
    components: {FontAwesomeIcon},
    props: {
        columns : Array,
        rows : Array,
        rowClick: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        handleRowClick(row, colClass, key) {
            if(colClass.includes('text-brand'))
            {
                this.$emit('row-click', row, key);
            }
        }
    }
}
</script>

<style scoped>
.table{
    max-height: calc(100vh - 4rem - 114px);
}
</style>