<template>
    <div class="fixed flex items-center bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-30">
        <div class="mx-auto bg-white w-[600px] rounded-lg shadow-64 p-6 space-y-2">
            <div class="flex justify-between items-center">
                <h3 class="font-semibold text-xl">{{ title }}</h3>
                <button type="button" @click="closePopup" class=" text-zinc-500">
                    <FontAwesomeIcon :icon="['fas', 'xmark']"/>
                </button>
            </div>
            <slot></slot>
            <div class="flex flex-row-reverse">
                <InlineButton :buttonText="whiteText" whiteButton class="ml-2" @click="closePopup"/>
                <InlineButton :buttonText="blueText" blueButton @click="getResult"/>
            </div>
        </div>
    </div>
</template>

<script>
import InlineButton from "@/components/button/InlineButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name      : "TextAlert",
    components: {FontAwesomeIcon, InlineButton},
    props     : {
        title    : String,
        whiteText: String,
        blueText : String,
    },
    methods   : {
        getResult() {
            this.$emit('get-result');
        },
        closePopup() {
            this.$emit('close');
        },
    }
}
</script>

<style scoped>

</style>