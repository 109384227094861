<template>
    <PopupBox title="제휴승인" :tabs="popupTabs" showWhiteButton whiteButtonText="반려하기" showBlueButton 
            blueButtonText="승인하기" @whiteClick="toggleTextBox" :popNm="'factoryService'" @blueClick="getApprConfirm">
        <template v-slot:header>
            <popupHead v-for="(tab, index) in popupTabs" :key="index" @click="getTabInfo(tab.value)"
                       :class="{ active : activeTab === tab.value }">
                {{ tab.title }}
            </popupHead>
        </template>
        <template v-slot:content>
            <popupBody v-if="activeTab === 1" class="space-y-4">
                <LabelInput type="text" label="가입일시" useTooltip tooltipContent="" :value="info['tab1']['joinDt']" disabled/>
                <LabelInput type="text" label="심사요청 일시" useTooltip tooltipContent="" :value="info['tab1']['reqDt']" disabled/>
                <LabelInput type="text" label="반려 횟수" useTooltip tooltipContent="" :value="info['tab1']['reCnt']" disabled/>
                <LabelTextarea label="반려 사유" useTooltip tooltipContent="" rows="2" :value="info['tab1']['reMemo']" disabled/>
                <ModalTitle title="기본 회원 정보"/>
                <LabelInput type="text" label="플랫폼 구분" useTooltip tooltipContent="" :value="info['tab1']['platForm']" disabled/>
                <LabelInput type="text" label="가입 채널" useTooltip tooltipContent="" :value="info['tab1']['channel']" disabled/>
                <LabelInput type="text" label="아이디 (이메일)" useTooltip tooltipContent="" :value="info['tab1']['email']" disabled/>
                <LabelInput type="text" label="이름" useTooltip tooltipContent="" :value="info['tab1']['name']" disabled/>
                <LabelInput type="text" label="연락처" useTooltip tooltipContent="" :value="info['tab1']['tel']" disabled/>
                <LabelImage label="사업자등록증 파일" useTooltip tooltipContent="" :fileList="info['tab1']['einFile']"/>
                <LabelInput type="text" label="이용약관 동의" useTooltip tooltipContent="" :value="info['tab1']['agree1']" disabled/>
                <LabelInput type="text" label="개인정보취급방침 동의" useTooltip tooltipContent="" :value="info['tab1']['agree2']" disabled/>
                <ModalTitle title="공장 정보"/>
                <LabelInput type="text" label="공장시설 이름" useTooltip tooltipContent="" :value="info['tab1']['faNm']" disabled/>
                <LabelInput type="text" label="지역" useTooltip tooltipContent="" :value="info['tab1']['area']" disabled/>
                <LabelInput type="text" label="사업자등록번호" useTooltip tooltipContent="" :value="info['tab1']['einNum']" disabled/>
                <LabelInput type="text" label="사업자등록증상 대표자 이름" useTooltip tooltipContent="" :value="info['ceoNm']" disabled/>
                <LabelInput type="text" label="주소" useTooltip tooltipContent="" :value="info['tab1']['addr']" disabled/>
                <LabelInput type="text" label="상세주소" useTooltip tooltipContent="" :value="info['tab1']['addrDetail']" disabled/>
                <LabelInput type="text" label="공장 연락처" useTooltip tooltipContent="" :value="info['tab1']['faTel']" disabled/>
                <ModalTitle title="결제 정보"></ModalTitle>
                <LabelInput type="text" label="대표 결제수단" useTooltip tooltipContent="" disabled/>
                <LabelInput type="text" label="결제 시작일" useTooltip tooltipContent="" disabled/>
                <LabelInput type="text" label="결제별칭" useTooltip tooltipContent="" disabled/>
            </popupBody>

            <popupBody v-if="activeTab === 2" class="space-y-4">
                <LabelInput type="text" label="제휴요청 일시" useTooltip tooltipContent="" :value="info['tab1']['reqDt']" disabled/>
                <LabelInput type="text" label="반려 횟수" useTooltip tooltipContent="" :value="info['tab1']['reCnt']" disabled/>
                <LabelTextarea label="반려 사유" useTooltip tooltipContent="" rows="2" :value="info['tab1']['reMemo']" disabled/>
                <ModalTitle title="공장시설 소개"/>
                <LabelInput type="text" label="공장시설 이름" useTooltip tooltipContent="" :value="info['tab2']['faNm']" disabled/>
                <LabelTextarea label="공장시설 소개" useTooltip tooltipContent="" :value="info['tab2']['faMemo']" rows="15" disabled/>
                <LabelTextarea label="공지사항 (선택)" useTooltip tooltipContent="" :value="info['tab2']['notice']" rows="2" disabled/>
                <LabelTextarea label="기타 (선택)" useTooltip tooltipContent="" :value="info['tab2']['etc']" rows="2" disabled/>
                <!-- 공장시설 소개 -->
                <ModalTitle title="공장시설 소개"/>
                <LabelImage label="대표사진" useTooltip tooltipContent="" :fileList="info['tab2']['repImage']"/>
                <LabelImage label="공장관련 사진" useTooltip tooltipContent="" :fileList="info['tab2']['faReImage']"/>
                <ModalTitle title="운영시간"/>
                <LabelTextarea label="운영시간" useTooltip tooltipContent="" :value="info['tab2']['workTime']" rows="5" disabled/>
                <LabelTextarea label="기타 (선택)" useTooltip tooltipContent="" :value="info['tab2']['workMemo']" rows="5" disabled/>
                <ModalTitle title="정산 계좌"/>
                <LabelInput type="text" label="정산 계좌" useTooltip tooltipContent="" :value="info['tab2']['accInfo']" disabled/>
                <LabelImage label="대표자 명의 통장사본" useTooltip tooltipContent="" :fileList="info['tab2']['accImage']" />
                <ModalTitle title="홍보 웹사이트"/>
                <LabelTextarea label="홍보 웹사이트" useTooltip tooltipContent="" :value="info['tab2']['webSite']" rows="5" disabled/>
                <ModalTitle title="미리보기 영상"/>
                <LabelMovie label="대표사진" useTooltip :fileList="info['tab2']['repVideo']" @getVideo="getVideo" />
            </popupBody>
        </template>
    </PopupBox>
    <ShowMovie v-if="videoPop" :fileNm="info['tab2']['choiceVideo']" @getClose="videoPop = false"/>
    <TextAlert title="반려사유" v-if="showTextBox" @get-result="getRejectConfirm" @close="closeTextBox" whiteText="반려취소" blueText="반려하기">
        <LabelTextarea placeholder="예: 사업자등록증 파일이 제대로 첨부가 안 되어 있습니다.
        정확하게 첨부하여 재심사 부탁드립니다 :)" rows="6" hiddenLabel @input="getRejectMemo"/>
    </TextAlert>
    <Confirm v-if="confirmData['confirmStat']" :type="confirmData['confirmType']" :title="confirmData['confirmTitle']" :description="confirmData['confirmDescription']" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script setup>
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import PopupBox from "@/components/modal/PopupBox.vue";
import TextAlert from "@/components/modal/TextAlert.vue";
import LabelImage from "@/components/input/LabelImage.vue";
import LabelMovie from "@/components/input/LabelMovie.vue";
import ShowMovie from "@/components/modal/ShowMovie.vue";
import Confirm from "@/components/modal/Confirm.vue";

import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';

const store       = useStore();
const info        = computed(() => store.state.factoryService.info);
const activeTab   = computed(() => store.state.factoryService.tabGb);
const videoPop    = ref(false);
const apprGb      = ref('N');
const confirmData = reactive({
    confirmStat         : false,
    confirmType         : '',
    confirmTitle        : '',
    confirmDescription  : ''
});

const reJectMemo    = ref('');
const getRejectMemo = () => {
    reJectMemo.value = event.target.value;
0};

const getConfirmResult = () => {
    confirmData['confirmStat'] = false;

    if(apprGb.value === 'J')
    {
        const res = store.dispatch('factoryService/getAppr');

        if(res)
        {
            store.commit('popup/setStat', { name : 'factoryService', stat : false });
            store.dispatch('factoryService/getList', { apprGb : 'A' });
        }
    }
    else
    {
        const res = store.dispatch('factoryService/getReject', reJectMemo.value);

        if(res)
        {
            store.commit('popup/setStat', { name : 'factoryService', stat : false });
            store.dispatch('factoryService/getList', { apprGb : 'A' });
            reJectMemo.value  = '';
            showTextBox.value = false;
        }
    }
};

const getConfirmClose = () => {
    confirmData['confirmStat'] = false;
};

const getConfirmSet = (type, title, description) => {
    confirmData['confirmType']        = type;
    confirmData['confirmStat']        = true;
    confirmData['confirmTitle']       = title;
    confirmData['confirmDescription'] = description;
};

const getRejectConfirm = () => {
    apprGb.value = 'A';
    getConfirmSet('confirm', '반려하기', '반려하시겠습니까?');
}

const getApprConfirm = () => {
    apprGb.value = 'J';
    getConfirmSet('confirm', '승인하기', '승인하시겠습니까?');
}

const popupTabs = [
    {title: '일반정보', value : 1},
    {title: '제휴정보', value : 2}
];

const showTextBox = ref(false);

const getTabInfo = (val) => {
    store.commit('factoryService/setTabGb', val);

    if(activeTab.value === 1)
    {
        store.dispatch('factoryService/getTabInfo1');
    }
    else
    {
        store.dispatch('factoryService/getTabInfo2');
    }
}

const toggleTextBox = () => {
    showTextBox.value = true;
};

const closeTextBox = () => {
    showTextBox.value = false;
};

const getVideo = (fileNm) => {
    videoPop.value = true;
    info.value['tab2']['choiceVideo'] = fileNm;
}

</script>

<style scoped>

</style>