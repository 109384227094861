<template>
    <label :for="id" class="text-sm relative mb-0.5 block">
        {{ label }}
        <span v-if="important" class="text-red-500">*</span>
        <span v-if="useTooltip" class="relative" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
            <FontAwesomeIcon :icon="['fas', 'circle-info']" />
            <div v-if="showTooltip" class="absolute vertical-h py-1 px-3 rounded-sm text-xs min-w-48 left-2" ref="tooltipIcon">
            {{ tooltipContent }}
            </div>
        </span>
    </label>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name : "TooltipLabel",
    components: {FontAwesomeIcon},
    props: {
        id            : String,
        label         : String,
        important     : Boolean,
        tooltipContent: String,
        useTooltip    : Boolean,
    },
    data() {
        return {
            showTooltip: false,
            tooltipLeft: 0,
        };
    },
    computed: {
        tooltipStyle() {
            const tooltipWidth = this.tooltipContent.length * 8;

            return {
                left : this.tooltipLeft + 10 + "px",
                width: `${tooltipWidth}px`,
            };
        },
    },
    methods : {
        getIconLeftPosition() {
            const icon = this.$refs.tooltipIcon;
            if (icon) {
                const iconRect = icon.getBoundingClientRect();
                this.tooltipLeft = iconRect.left;
            }
        },
    },
    mounted() {
        this.getIconLeftPosition();
    },
}
</script>

<style scoped>

</style>