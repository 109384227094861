<template>
    <div class="popup w-full h-screen fixed top-0 left-0 z-10 items-center duration-200 bg-[rgba(0,0,0,.3)]" :class="{ active : popup[popNm] }" @click="closePopup">
    </div>
    <div class="popup-box bg-white fixed top-0 right-0 z-20 w-1/2 h-screen duration-300" :class="{ active : popup[popNm] }">
        <PopupHead :title="popupTitle" :tabs="tabs" :showWhiteButton="showWhiteButton"
                   :whiteButtonText="whiteButtonText" :showBlueButton="showBlueButton"
                   :blueButtonText="blueButtonText" @close="closePopup" @whiteClick="whiteClick" @blueClick="blueClick">
            <slot name="header"></slot>
        </PopupHead>
        <PopupBody>
            <slot name="content"></slot>
        </PopupBody>
    </div>
</template>

<script>
import PopupHead from "@/components/modal/PopupHead.vue";
import PopupBody from "@/components/modal/PopupBody.vue";
import { mapState } from "vuex";

export default {
    name      : "PopupBox",
    components: {PopupBody, PopupHead},
    computed: {
        ...mapState('popup', {
            popup  : state => state
        })
    },
    props     : {
        title          : String,
        tabs           : Array,
        showWhiteButton: Boolean,
        whiteButtonText: String,
        showBlueButton : Boolean,
        blueButtonText : String,
        popNm          : String 
    },
    data() {
        return {
            popupTitle  : this.title,
        }
    },
    methods: {
        closePopup() {
            this.popup[this.popNm] = false;
        },
        whiteClick() {
            this.$emit('whiteClick')
        },
        blueClick() {
            this.$emit('blueClick')
        }
    }
}
</script>

<style lang="scss" scoped>
.popup {
    display: none;

    &.active{
        display: flex;
    }

    &-box{
        max-width: 54.5rem;
        transform: translateX(100%);

        &.active{
            transform: translateX(0);
        }
    }
}
</style>