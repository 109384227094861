<template>
    <HeaderView v-if="showHeader"/>
    <div class="w-full flex box">
        <SideHeader v-if="showHeader"/>
        <div class="section overflow-scroll scroll-none bg-white">
            <router-view>
            </router-view>
        </div>
    </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from "@fortawesome/free-solid-svg-icons"
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import HeaderView from "@/views/common/HeaderView.vue";
import SideHeader from "@/views/common/SideHeader.vue";

library.add(fas, far, fab);

export default {
    name      : 'App',
    components: {SideHeader, HeaderView},
    computed  : {
        showHeader() {
            const currentPath = this.$route.path
            return !currentPath.startsWith('/login') && !currentPath.startsWith('/center/join')
        }
    }
}
</script>

<style>
@import "@/assets/scss/globalStyle.scss";

.box {
    height: calc(100vh - 4rem);

    .section {
        width: calc(100% - 4rem);
    }
}
</style>
