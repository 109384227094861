<template>
    <div class="relative space-x-2.5 flex items-center">
        <div v-for="(radio, index) in radioOptions" :key="index">
            <input type="radio" :id="radio.id" :name="name" class="hidden peer/button" checked />
            <label :for="radio.id" class="px-3 h-9 flex items-center cursor-pointer rounded border border-neutral-300 bg-white font-semibold text-sm peer-checked/button:bg-brand peer-checked/button:border-brand peer-checked/button:text-white" @click="getRadio(radio.value)">
                {{ radio.label }}
            </label>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
    name            : String,
    radioOptions    : Array
});

const emit = defineEmits('getRadio');

const getRadio = (val) => {
    emit('getRadio', val);
}
</script>

<style scoped>

</style>