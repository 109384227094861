<template>
    <div class="w-full border-b border-neutral-300 py-3 bg-white flex items-center space-x-4">
        <FontAwesomeIcon :icon="['fas', 'arrow-down-wide-short']" class="cursor-pointer ml-6 text-zinc-500"/>
        <ul class="flex items-center" v-if="showFilter">
            <li v-for="(filter, index) in filters" :key="index" class="p-3" @click="getClick(filter.value)">
                <span class="text-sm hover:font-semibold hover:border-b-2 border-brand py-2 cursor-pointer">
                    {{ filter.title }}
                    ({{ filter.count }})
                </span>
            </li>
        </ul>
    </div>
    <slot></slot>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits } from "vue";

defineProps({
    filters   : Array,
    showFilter: Boolean
});

const emit = defineEmits('getFilterClick');

const getClick = (val) => {
    emit('getFilterClick', val);
}
</script>

<style scoped>
ul{
    li{
        a{
            &.active{
                border-bottom-width: 2px;
                font-weight: 600;
            }
        }
    }
}
</style>