<template>
    <div class="w-full border-b border-neutral-300 py-2 bg-white">
        <div class="w-full py-2 flex items-center">
            <img src="../../assets/images/icon-home.png" alt="home" title="home" class="m-1.5 ml-6" />
            <h2 class="px-3 mx-3 font-bold text-zinc-700">{{ title }}</h2>
            <ul class="flex items-center">
                <li v-for="(item, index) in menuItems" :key="index" class="text-sm mx-2.5">
                    <router-link :to="item.to" class="py-2 hover:border-b-2 border-brand hover:font-semibold duration-100" :class="{ 'active': isLinkActive(item.to) }">{{ item.label }}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "SectionHeader",
    props: {
        title: String,
        menuItems: Array,
    },
    methods: {
        isLinkActive(to) {
            return this.$route.path.includes(to);
        },
    }
}
</script>

<style scoped>
ul{
    li{
        a{
            &.active{
                border-bottom-width: 2px;
                font-weight: 600;
            }
        }
    }
}
</style>