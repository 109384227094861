<template>
    <button type="button" class="w-full rounded text-center font-bold text-sm py-3 mt-10" :class="{'bg-neutral-200': greyButton, 'bg-blue-600': blueButton}">{{ buttonText }}</button>
</template>

<script>
export default {
    name: "FullButton",
    props: {
        buttonText: String,
        greyButton: Boolean,
        blueButton: Boolean,
    }
}
</script>

<style scoped>

</style>