<template>
    <div class="flex items-center w-full py-2.5">
        <div class="flex-grow h-px bg-zinc-300"></div>
        <h5 class="text-xs font-normal mx-3">{{ title }}</h5>
        <div class="flex-grow h-px bg-zinc-300"></div>
    </div>
</template>

<script>
export default {
    name: "ModalTitle",
    props: {
        title: String,
    }
}
</script>

<style scoped>

</style>