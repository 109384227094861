<template>
    <div class="relative block w-full">
        <TooltipLabel :for="id" :label="label" :important="important" :useTooltip="useTooltip" :tooltipContent="tooltipContent"/>
        <div class="rounded-lg bg-neutral-100 p-1 inline-block">
            <div v-for="(option, index) in options" :key="index" class="inline-block min-w-16" @click="getClick(option.value)">
                <input type="radio" :id="option.id" :name="name" :value="option.value" v-model="checkedValue" :disabled="disabled" class="hidden peer/radio"/>
                <label :for="option.id" class="text-xs font-semibold text-center py-1.5 px-3 rounded-lg block text-neutral-500 peer-checked/radio:bg-white peer-checked/radio:text-brand peer-checked/radio:shadow-md">
                    {{ option.text }}
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
import TooltipLabel from "@/components/input/TooltipLabel.vue";
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
    id            : String,
    name          : String,
    checkedVal    : String,
    label         : String,
    important     : Boolean,
    useTooltip    : Boolean,
    tooltipContent: String,
    options       : Array,
    disabled      : Boolean
});

const emit = defineEmits('getRadio');

const checkedValue = ref(props.checkedVal);

watch(() => props.checkedVal, (newValue) => {
    checkedValue.value = newValue;
});

const getClick = (val) => {
    emit('getRadio', val);
}
</script>

<style scoped>

</style>