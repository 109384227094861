<template>
    <ApprHeader />
    <FilterBox />
    <StikyTable :columns="ApprList" :rows="list" @row-click="getListClick"/>
    <CenterAllianceApprListPopup />
</template>

<script setup>
import ApprHeader from "@/views/common/ApprHeader.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import StikyTable from "@/components/table/StikyTable.vue";
import CenterAllianceApprListPopup from "@/views/center/approve/AllianceApprListPopup.vue";

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const ApprList = [
    { key: 'email', header: '가입 이메일', class: 'text-brand w17' },
    { key: 'nation', header: '국가', class: 'w10' },
    { key: 'area', header: '지역', class: 'w10' },
    { key: 'ceNm', header: '센터명', class: 'w13' },
    { key: 'ceoNm', header: '대표자', class: 'w10' },
    { key: 'tel', header: '휴대폰', class: 'w10' },
    { key: 'einNum', header: '사업자등록증', class: 'w10' },
    { key: 'joinDt', header: '가입 일시', class: 'w10' },
    { key: 'regDt', header: '심사요청 일시', class: 'w10' }
];

const list = computed(() => store.state.centerService.list);

/** 화면 호출 시 */
onMounted(() => {
    store.commit('popup/setStat', { name : 'centerService', stat : false });
    getList();
});

/** 리스트 호출 */
const getList = () => {
    store.dispatch('centerService/getList', { apprGb : 'A' });
}

/** 리스트 클릭 시 */
const getListClick = (data) => {
    store.commit('centerService/setTabGb', 1);
    store.commit('centerService/setCeCd', data['ceCd']);
    store.commit('centerService/setUserCd', data['userCd']);

    store.dispatch('centerService/getTabInfo1');
    store.commit('popup/setStat', { name : 'centerService', stat : true });
}

</script>

<style scoped>

</style>