<template>
    <ModalBox title="공장 회원가입 2/2">
        <GreyBox class="mb-7">
            <p class="text-sm leading-6">
                플랜오더에 등록하실 운영 중인 공장시설 정보와 플랜 및 문자메시지 등 플랜오더 이용을 위한 결제수단을 입력해주세요.<br>
                <b>* 안심하세요! 결제수단을 등록해도, 2주 무료체험 기간 동안 절대 결제가 일어나지 않고 언제든 해지가 가능해요.</b>
            </p>
            <p class="text-xm mt-2.5">
                무료체험 기간 동안 이용을 해지하지 않으시면 무료체험 종료일 오전 10시에 자동으로 결제되며, 매월 25일 정기결제가 이루어집니다.
            </p>
        </GreyBox>
        <div class="space-y-5">
            <LabelInput type="text" id="" name="" label="공장시설 이름" placeholder="공장시설 이름을 입력해주세요" useTooltip tooltipContent="" />
            <LabelSelect id="" name="" label="지역" placeholder="지역 선택" useTooltip tooltipContent="" :options="locations" />
            <LabelRadio id="" name="3" label="사업자 구분" useTooltip tooltipContent="" :options="radio" />
            <LabelInput type="number" id="" name="" label="사업자등록번호" placeholder="-없이 입력해주세요" useTooltip tooltipContent="" />
            <LabelInput type="text" id="" name="" label="사업자등록증상 대표자 이름" placeholder="대표자 이름을 입력해주세요" useTooltip tooltipContent="" />
            <LabelInput type="text" id="" name="" label="주소" placeholder="주소를 검색해주세요" useTooltip tooltipContent="" />
            <LabelInput type="text" id="" name="" label="상세주소" placeholder="상세주소를 입력해주세요" useTooltip tooltipContent="" />
            <LabelInput type="number" id="" name="" label="공장 대표 연락처" placeholder="-없이 입력해주세요" useTooltip tooltipContent="" />
        </div>
        <FullButton buttonText="공장시설 정보 제출하기" greyButton />
    </ModalBox>
</template>

<script>
import ModalBox from "@/components/modal/ModalBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import LabelSelect from "@/components/input/LabelSelect.vue";
import FullButton from "@/components/button/FullButton.vue";
import LabelRadio from "@/components/input/LabelRadio.vue";
import GreyBox from "@/components/common/GreyBox.vue";

export default {
    name: "FactoryJoinSecond",
    components: {GreyBox, LabelRadio, FullButton, LabelSelect, LabelInput, ModalBox},
    data() {
        return{
            locations: [
                {label: '서울'},
                {label: '부산'},
                {label: '울산'},
            ],
            radio: [
                { id: '1', value: '', text: '개인' },
                { id: '2', value: '', text: '법인' },
            ]
        }
    }
}
</script>

<style scoped>

</style>