<template>
    <div class="w-full">
        <div class="w-full flex items-center justify-between h-8">
            <h5 class="px-3 text-xs ml-1">[ {{ title }} ]</h5>
            <div class="text-zinc-500">
                <button type="button" class="px-3">
                    <FontAwesomeIcon :icon="['fas', 'maximize']"/>
                </button>
                <button type="button" @click="closePopup" class="px-3 mr-1">
                    <FontAwesomeIcon :icon="['fas', 'xmark']"/>
                </button>
            </div>
        </div>

        <div class="p-2 pr-5 flex justify-between items-center border-b border-neutral-200">
            <div class="flex ml-1 items-center">
                <FontAwesomeIcon :icon="['fas', 'arrow-left']" @click="closePopup" class="p-3 cursor-pointer"/>
                <div @click="handleClick" class="text-sm">
                    <slot></slot>
                </div>
            </div>
            <div class="space-x-5">
                <InlineButton v-if="showWhiteButton" :buttonText="whiteButtonText" whiteButton @click="whiteClick" />
                <InlineButton v-if="showBlueButton" :buttonText="blueButtonText" blueButton @click="blueClick"/>
            </div>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import InlineButton from "@/components/button/InlineButton.vue";

export default {
    name      : "PopupHead",
    components: {InlineButton, FontAwesomeIcon},
    props     : {
        title          : String,
        showWhiteButton: Boolean,
        whiteButtonText: String,
        showBlueButton : Boolean,
        blueButtonText : String,
    },
    methods   : {
        selectTab(index) {
            this.selectedTabIndex = index;
        },
        closePopup() {
            this.$emit('close');
        },
        handleClick() {
            this.$emit('click')
        },
        whiteClick() {
            this.$emit('whiteClick')
        },
        blueClick() {
            this.$emit('blueClick')
        }
    }
}
</script>

<style lang="scss">
popupHead {
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding-bottom: 0.5rem;
    transition: border .15s ease-in-out;
    cursor: pointer;

    &.active, &:hover {
        border-bottom: 2px solid $brand;
        font-weight: 600;
    }
}
</style>