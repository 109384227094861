<template>
    <PopupBox title="일반회원" :tabs="popupTabs" showBlueButton blueButtonText="저장하기" :popNm="'factoryNormalMember'" @blueClick="getSaveConfirm">
        <template v-slot:header>
            <popupHead v-for="(tab, index) in popupTabs" :key="index" @click="getTabInfo(tab.value)"
                    :class="{ active : activeTab === tab.value }">
                {{ tab.title }}
            </popupHead>
        </template>
        <template v-slot:content>
            <popupBody v-if="activeTab === 1" class="space-y-4">
                <LabelRadio name="state" label="활동 / 정지 구분" useTooltip tooltipContent :options="activeOption" :checkedVal="info['tab1']['state']" 
                    @getRadio="getRadio" disabled/>
                <LabelInput type="text" label="활동상태" useTooltip tooltipContent="" :value="info['tab1']['state'] === 'Y' ? '활동' : '정지'" disabled />
                <LabelInput type="text" label="누적 명세표 건수"  useTooltip tooltipContent="" :value="info['tab1']['omCnt']" disabled />
                <ButtonInput type="text" label="연간 일반 매출" useToolTip tooltipContent :value="info['tab1']['saleCnt']" disabled buttonText="매출 원장" />
                <ButtonInput type="text" label="연간 일반 매입" useToolTip tooltipContent :value="info['tab1']['purcCnt']" disabled buttonText="매입 원장" />
                <ButtonInput type="text" label="서비스 결제" useToolTip tooltipContent disabled buttonText="결제 원장" />
                <LabelTextarea label="메모" useTooltip tooltipContent="" :value="info['tab1']['memo']" rows="5" @input="getMemo"/>
                <ModalTitle title="기본 회원 정보"/>
                <LabelInput type="text" label="최근 로그인" useTooltip tooltipContent="" :value="info['tab1']['lastDt']" disabled/>
                <LabelInput type="text" label="가입일시" useTooltip tooltipContent="" :value="info['tab1']['regDt']" disabled/>
                <LabelInput type="text" label="심사요청 일시" useTooltip tooltipContent="" :value="info['tab1']['apprDt']" disabled/>
                <LabelInput type="text" label="반려 횟수" useTooltip tooltipContent="" :value="info['tab1']['rejectCnt']" disabled/>
                <LabelTextarea label="반려 사유" useTooltip tooltipContent="" rows="2" :value="info['tab1']['rejectMemo']" disabled/>
                <ModalTitle title="기본 회원 정보"/>
                <LabelInput type="text" label="플랫폼 구분" useTooltip tooltipContent="" :value="info['tab1']['platForm']" disabled/>
                <LabelInput type="text" label="가입 채널" useTooltip tooltipContent="" :value="info['tab1']['channel']" disabled/>
                <LabelInput type="text" label="아이디 (이메일)" useTooltip tooltipContent="" :value="info['tab1']['id']" disabled/>
                <LabelInput type="text" label="이름" useTooltip tooltipContent="" :value="info['tab1']['name']" disabled/>
                <LabelInput type="text" label="연락처" useTooltip tooltipContent="" :value="info['tab1']['tel']" disabled/>
                <!-- 사업자등록증 파일 -->
                <LabelInput type="text" label="이용약관 동의" useTooltip tooltipContent="" :value="info['tab1']['agreeDt1']" disabled/>
                <LabelInput type="text" label="개인정보취급방침 동의" useTooltip tooltipContent="" :value="info['tab1']['agreeDt2']" disabled/>
                <ModalTitle title="공장 정보"/>
                <LabelInput type="text" label="공장시설 이름" useTooltip tooltipContent="" :value="info['tab1']['faNm']" disabled/>
                <LabelInput type="text" label="지역" useTooltip tooltipContent="" :value="info['tab1']['area']" disabled/>
                <LabelInput type="text" label="사업자등록번호" useTooltip tooltipContent="" :value="info['tab1']['einNum']" disabled/>
                <LabelInput type="text" label="사업자등록증상 대표자 이름" useTooltip tooltipContent="" :value="info['tab1']['ceoNm']" disabled/>
                <LabelInput type="text" label="주소" useTooltip tooltipContent="" :value="info['tab1']['addr']" disabled/>
                <LabelInput type="text" label="상세주소" useTooltip tooltipContent="" :value="info['tab1']['addrDetail']" disabled/>
                <LabelInput type="text" label="공장 연락처" useTooltip tooltipContent="" :value="info['tab1']['faTel']" disabled/>
                <ModalTitle title="결제 정보"></ModalTitle>
                <LabelInput type="text" label="대표 결제수단" useTooltip tooltipContent="" disabled/>
                <LabelInput type="text" label="결제 시작일" useTooltip tooltipContent="" disabled/>
                <LabelInput type="text" label="결제별칭" useTooltip tooltipContent="" disabled/>
            </popupBody>
            <popupBody v-if="activeTab === 2">
                <PopupTable :columns="memberList" :rows="info['tab2']" />
            </popupBody>
        </template>
    </PopupBox>

    <TextAlert title="활동변경" v-if="checkedValue === 'Y'" blueText="활동변경" whiteText="취소" @close="checkedValue=null" @get-result="getResult">
        <p class="text-sm">정지 해제를 하고 활동으로 변경하시겠습니까?</p>
    </TextAlert>
    <TextAlert title="정지" v-if="checkedValue === 'N'" @get-result="getStopConfirm" @close="checkedValue=null" blueText="정지하기" whiteText="취소">
        <div class="h-1"></div>
        <div class="flex space-x-2.5">
            <LabelInput hiddenLabel type="date" class="w-36" :value="stopInfo['startDt']" />
            <LabelInput hiddenLabel type="date" class="w-36" :value="stopInfo['endDt']" />
            <ButtonRadio :radioOptions="radioOptions" name="useYn" class="w-[15rem]" @getRadio="getDateSet"/>
        </div>
        <div class="h-1"></div>
        <LabelTextarea rows="4" label="반려사유" useTooltip tooltipContent="" :value="stopInfo['memo']" @input="getMngMemo"/>
    </TextAlert>
    <Confirm v-if="confirmData['confirmStat']" :type="confirmData['confirmType']" :title="confirmData['confirmTitle']" :description="confirmData['confirmDescription']" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script setup>
import PopupBox from "@/components/modal/PopupBox.vue";
import LabelRadio from "@/components/input/LabelRadio.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import ButtonInput from "@/components/input/ButtonInput.vue";
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import PopupTable from "@/components/table/PopupTable.vue";
import TextAlert from "@/components/modal/TextAlert.vue";
import ButtonRadio from "@/components/input/ButtonRadio.vue";
import Confirm from "@/components/modal/Confirm.vue";

import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { getDate, getConvertDate } from '@/assets/js/function';

const store       = useStore();
const info        = computed(() => store.state.factoryNormalMember.info);
const stopInfo    = computed(() => store.state.factoryNormalMember.stopInfo);
const activeTab   = computed(() => store.state.factoryNormalMember.tabGb);
const confirmData = reactive({
    confirmStat         : false,
    confirmType         : '',
    confirmTitle        : '',
    confirmDescription  : ''
});

const getConfirmResult = () => {
    confirmData['confirmStat'] = false;

    getResult();
}

const getConfirmClose = () => {
    confirmData['confirmStat'] = false;
};

const getConfirmSet = (type, title, description) => {
    confirmData['confirmType']        = type;
    confirmData['confirmStat']        = true;
    confirmData['confirmTitle']       = title;
    confirmData['confirmDescription'] = description;
};

const getStopConfirm = () => {
    getConfirmSet('confirm', '정지하기', '해당 계정을 정지하시겠습니까?');
}

const getSaveConfirm = () => {
    getConfirmSet('confirm', '저장하기', '저장하시겠습니까?');
}

const popupTabs   = [
    { title: '계정정보', value : 1 },
    { title: '계정 연동 정보', value : 2 }
];
const checkedValue = ref(null);
const activeOption = [
    { id: 'Y', value: 'Y', text: '활동' },
    { id: 'N', value: 'N', text: '정지' }
];
const memberList = [
    { key: 'email', header: '아이디', class: 'w28' },
    { key: 'nation', header: '신청일', class: 'w18' },
    { key: 'region', header: '최근 접속일', class: 'w18' },
    { key: 'fcNm', header: '접속횟수', class: 'w18' },
    { key: 'useYn', header: '관리자 정지', class: 'w18' }
];
const radioOptions = [
    { id: '7d', label: '7일', value : 7 },
    { id: '30d', label: '30일', value : 30 },
    { id: '60d', label: '60일', value : 60 },
    { id: 'eternity', label: '영구', value : 'INF' }
];

const getMemo = () => {
    info.value['tab1']['memo'] = event.target.value;
}

const getResult = () => {
    const data = {
        state : checkedValue.value ? checkedValue.value : info.value['tab1']['state'],
        memo  : info.value['tab1']['memo']
    };

    const result = store.dispatch('factoryNormalMember/getResult', data);

    result.then((res) => {
        if(res.code === 200)
        {
            getConfirmClose();
            checkedValue.value = null;
            store.dispatch('factoryNormalMember/getList', { state : 'Y' });
            store.commit('factoryNormalMember/setStopInfoReset');
            store.commit('popup/setStat', { name : 'factoryNormalMember', stat : false });
        }
        else
        {
            alert('데이터 처리에 실패하였습니다. 지속될 경우 담당자에게 문의하세요.');
        }
    });
}

const getTabInfo = (val) => {
    store.commit('factoryNormalMember/setTabGb', val);

    if(activeTab.value === 1)
    {
        store.dispatch('factoryNormalMember/getTabInfo1');
    }
    else
    {
        store.dispatch('factoryNormalMember/getTabInfo2');
    }
}

const getRadio = (val) => {
    if(info.value['tab1']['state'] !== val)
    {
        checkedValue.value = val;
    }
}

const getDateSet = (val) => {
    stopInfo.value['choice'] = val;

    if(val === 'INF')
    {
        stopInfo.value['startDt'] = '';
        stopInfo.value['endDt']   = '';
    }
    else
    {
        const date = getDate(val);

        stopInfo.value['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
        stopInfo.value['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');
    }
}

const getMngMemo = () => {
    stopInfo.value['memo'] = event.target.value;
}
</script>

<style scoped>

</style>