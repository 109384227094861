<template>
    <button type="button" class="pt-2 pb-1.5 px-3 border rounded text-sm font-semibold"
            :class="{'bg-white text-black border-neutral-300': whiteButton, 'bg-brand text-white border-brand': blueButton}">
        {{ buttonText }}
    </button>
</template>

<script>
export default {
    name : "InlineButton",
    props: {
        buttonText : String,
        whiteButton: Boolean,
        blueButton : Boolean,
    }
}
</script>

<style scoped>

</style>