<template>
    <div class="relative block w-full">
        <TooltipLabel :for="id" :label="label" :important="important" :useTooltip="useTooltip" :tooltipContent="tooltipContent"/>
        <select :id="id" :name="name" class="rounded w-full h-9 duration-200 border border-zinc-300 border-b-zinc-500 p-2 text-sm" :class="{'border-zinc-300': !error, 'border-red-600': error}" required>
            <!-- 맨 위 옵션은 placeholder 기능만 하며 실제 데이터는 x -->
            <option value="" selected disabled class="hidden">{{ placeholder }}</option>
            <option v-for="(option, index) in options" :key="index" :value="option.value">
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
import TooltipLabel from "@/components/input/TooltipLabel.vue";

export default {
    name: "LabelSelect",
    components: {TooltipLabel},
    props     : {
        id            : String,
        name          : String,
        type          : String,
        label         : String,
        placeholder   : String,
        important     : Boolean,
        useTooltip    : Boolean,
        tooltipContent: String,
        error      : {
            type   : Boolean,
            default: false,
        },
        options    : Array,
    }
}
</script>

<style scoped>

</style>