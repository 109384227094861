<template>
    <div class="fixed flex items-center bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-30">
        <div class="mx-auto bg-white w-full min-w-80 h-screen sm:w-80 sm:h-4/5 overflow-y-scroll scroll-none">
            <div class="flex py-3.5 px-4 items-center justify-between relative">
                <img src="@/assets/images/btn-prev.svg" alt="이전" title="이전" @click="getClose" v-if="!hiddenPrev" />
                <h3 class="text-center w-full font-bold text-sm mt-0.5">{{ title }}</h3>
            </div>
            <div class="section w-full scroll-none px-4 pt-5 pb-16" :class="{'height' : showFixedButton}">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name      : "ModalBox",
    props     : {
        title          : String,
        buttonText     : String,
        blueButtonText : String,
        buttonGreyText : String,
        showBlueButton : Boolean,
        showGreyButton : Boolean,
        showFixedButton: Boolean,
        hiddenPrev     : Boolean,
    },
    methods   : {
        getClose() {
            this.$emit("close");
        }
    }
}
</script>

<style lang="scss" scoped>
.section {
    height: calc(100vh - 50px);
    overflow: scroll;

    &.height {
        height: calc(100vh - 112px);
    }
}
</style>