import { createWebHistory, createRouter } from "vue-router";
import store from "@/store"; // Vuex store import

import FactoryJoinFirst from "@/views/factory/join/JoinFirst.vue";
import FactoryJoinSecond from "@/views/factory/join/JoinSecond.vue";

import FactorApprList from "@/views/factory/approve/ApprList.vue";
import FactoryAllianceApprList from "@/views/factory/approve/AllianceApprList.vue";
import FactoryMemberList from "@/views/factory/member/MemberList.vue";
import FactoryAllianceMemberList from "@/views/factory/member/AllianceMemberList.vue";

import CenterApprList from "@/views/center/approve/ApprList.vue";
import CenterAllianceApprList from "@/views/center/approve/AllianceApprList.vue";
import CenterMemberList from "@/views/center/member/MemberList.vue";
import CenterAllianceMemberList from "@/views/center/member/AllianceMemberList.vue";
import LoginPage from "@/views/login/LoginPage.vue";

const routes = [
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
    },
    // factory
    {
        path: "/factory/join",
        name: "FactoryJoinFirst",
        component: FactoryJoinFirst
    },
    {
        path: "/factory/join2",
        name: "FactoryJoinSecond",
        component: FactoryJoinSecond
    },
    {
        path: "/factory/apprList",
        name: "FactorApprList",
        component: FactorApprList
    },
    {
        path: "/factory/allianceApprList",
        name: "FactoryAllianceApprList",
        component: FactoryAllianceApprList,
    },
    {
        path: "/factory/memberList",
        name: "FactoryMemberList",
        component: FactoryMemberList
    },
    {
        path: "/factory/allianceMemberList",
        name: "FactoryAllianceMemberList",
        component: FactoryAllianceMemberList,
    },

    // center
    {
        path: "/center/apprList",
        name: "CenterApprList",
        component: CenterApprList
    },
    {
        path: "/center/allianceApprList",
        name: "CenterAllianceApprList",
        component: CenterAllianceApprList,
    },
    {
        path: "/center/memberList",
        name: "CenterMemberList",
        component: CenterMemberList
    },
    {
        path: "/center/allianceMemberList",
        name: "CenterAllianceMemberList",
        component: CenterAllianceMemberList
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const jwt       = store.state.login.jwt;
    const vueList   = ['LoginPage'];
    const isCheck   = vueList.includes(to.name);

    if (jwt === null && !isCheck)
    {
        next({ name : 'LoginPage' });
    } 
    else 
    {
        next();
    }
});

export default router