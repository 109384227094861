<template>
    <SectionHeader title="회원관리" :menuItems="menuItems" />
</template>

<script>
import SectionHeader from "@/components/common/SectionHeader.vue";

export default {
    name: "MemberHeader",
    components: {SectionHeader},
    data(){
        return {
            menuItems: [
                {to: "/factory/memberList", label: "공장 일반회원"},
                {to: "/center/memberList", label: "매장 일반회원"},
                {to: "/factory/allianceMemberList", label: "공장 제휴회원"},
                {to: "/center/allianceMemberList", label: "매장 제휴회원"},
            ]
        }
    }
}
</script>

<style scoped>

</style>