<template>
    <div class="rounded-lg p-5 bg-neutral-100">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "GreyBox"
}
</script>

<style scoped>

</style>