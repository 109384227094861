import { getAxiosData } from '@/assets/js/function';
import router from '@/router'

/**
 * @description 공장 승인 요청 vuex store module
 * @author 김원명, @date 2024-02-23
 */
const factoryServiceModule = {
    namespaced : true,
    state: {
        faCd   : '',
        userCd : '',
        tabGb  : 1,
        list   : [],
        info   : getInfo()
    },
    mutations: {
        setFaCd(state, faCd)
        {
            state.faCd = faCd
        },
        setUserCd(state, userCd)
        {
            state.userCd = userCd;
        },
        setTabGb(state, gb)
        {
            state.tabGb = gb;
        },
        setList(state, list) 
        {
            state.list = list;
        },
        setTabInfo(state, { tabGb, info }) 
        {
            state.info[tabGb] = info;
        }
    },
    actions: {
        /** 리스트 호출 */
        async getList (context, data)
        {
            try
            {
                const res = await getAxiosData('https://po-data.plansys.kr/fac/getList', data);

                console.log(res);

                const list = [];

                res.data.map(item => {
                    list.push({
                        faCd    : item.faCd,
                        userCd  : item.userCd,
                        email   : item.email,
                        nation  : '대한민국',
                        area    : item.area,
                        faNm    : item.faNm,
                        ceoNm   : item.ceoNm,
                        tel     : item.tel,
                        einNum  : item.einNum,
                        joinDt  : '',
                        regDt   : item.regDt
                    })
                });

                context.commit('setList', list);
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상세 정보 호출 (일반정보 - tab 1) */
        async getTabInfo1 (context)
        {
            try
            {
                const params = {
                    faCd   : context.state.faCd,
                    userCd : context.state.userCd
                };
                const res    = await getAxiosData('https://po-data.plansys.kr/facList/getInfo', params);

                console.log(res);

                const info = {
                    joinDt      : res.data['regDt'],
                    reqDt       : res.data['apprDt'],
                    reCnt       : res.data['rejectCnt'],
                    reMemo      : res.data['rejectMemo'],
                    platForm    : '플랜오더 공장',
                    channel     : res.data['channel'],
                    email       : res.data['id'],
                    name        : res.data['name'],
                    tel         : res.data['tel'],
                    einFile     : res.data['einFilePath'] ? [res.data['einFilePath']] : [],
                    agree1      : res.data['agreeDt1'] ? res.data['agreeDt1'] : '',
                    agree2      : res.data['agreeDt2'] ? res.data['agreeDt2'] : '',
                    faNm        : res.data['faNm'],
                    area        : res.data['area'],
                    einNum      : res.data['einNum'],
                    ceoNm       : res.data['ceoNm'],
                    addr        : res.data['addr'],
                    addrDetail  : res.data['addrDetail'],
                    faTel       : res.data['faTel']
                };

                context.commit('setTabInfo', { tabGb : 'tab1', info : info });
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상세 정보 호출 (제휴정보 - tab 2) */
        async getTabInfo2 (context)
        {
            try
            {
                const params = {
                    faCd   : context.state.faCd,
                    userCd : context.state.userCd
                };
                const res    = await getAxiosData('https://po-data.plansys.kr/fac/getInfo', params);

                const workData = JSON.parse(res.data['workData']);

                let workTime = `[월요일] ${workData['monSt']} ~ ${workData['monEd']}\n[화요일] ${workData['tueSt']} ~ ${workData['tueEd']}\n[수요일] ${workData['wedSt']} ~ ${workData['wedEd']}\n[목요일] ${workData['tueSt']} ~ ${workData['tueEd']}\n[금요일] ${workData['friSt']} ~ ${workData['friEd']}\n`;
                workTime += `[공휴일] ${res.data['holiday'] === 'Y' ? '운영' : '휴무'}\n`;
                workTime += `[휴무일] `;

                const info = {
                    faNm        : res.data['faNm'],
                    intro       : res.data['intro'],
                    notice      : res.data['notice'],
                    etc         : res.data['etc'],
                    repImage    : res.data['filePath'] ? [res.data['filePath']] : [],
                    faReImage   : res.data['faFileList'] ? [res.data['faFileList']] : [],
                    workTime    : workTime,
                    workMemo    : res.data['workMemo'],
                    accInfo     : res.data['bankNm']+' / '+res.data['accNm']+' / '+res.data['accNum'],
                    accImage    : res.data['accFilePath'] ? [res.data['accFilePath']] : [],
                    webSite     : '[홈페이지]'+res.data['homepage']+'\n[네이버 블로그]'+res.data['blog']+'\n[인스타그램]'+res.data['insta']+'\n[페이스북]'+res.data['facebook']+'\n[카카오]'+res.data['kakao'],
                    repVideo    : res.data['faMovieList'] ? res.data['faMovieList'] : [],
                    choiceVideo : ''
                };

                context.commit('setTabInfo', { tabGb : 'tab2', info : info });
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 제휴신청 승인 */
        async getAppr (context)
        {
            try
            {
                const params = {
                    faCd   : context.state.faCd,
                    userCd : context.state.userCd
                };

                const res    = await getAxiosData('https://po-data.plansys.kr/fac/facAllianceAccess', params);

                console.log(res);

                if(res.status === 200)
                {
                    return true;
                }                
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }

                return false;
            }
        },
        /** 제휴반려 처리 */
        async getReject(context, memo)
        {
            try
            {
                const params = {
                    faCd   : context.state.faCd,
                    userCd : context.state.userCd,
                    memo   : memo
                };

                const res    = await getAxiosData('https://po-data.plansys.kr/fac/facAllianceReject', params);

                if(res.status === 200)
                {
                    return true;
                }
            }
            catch(e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }

                return false;
            }
        }
    }
};

function getInfo()
{
    return {
        tab1 : {
            joinDt      : '',
            reqDt       : '',
            reCnt       : 0,
            reMemo      : '',
            platForm    : '플랜오더 공장',
            channel     : '',
            email       : '',
            name        : '',
            tel         : '',
            einFile     : '',
            agree1      : '',
            agree2      : '',
            faNm        : '',
            area        : '',
            einNum      : '',
            ceoNm       : '',
            addr        : '',
            addrDetail  : '',
            faTel       : '',
        },
        tab2 : {
            faNm        : '',
            intro       : '',
            notice      : '',
            etc         : '',
            repImage    : [],
            faReImage   : [],
            workTime    : '',
            workMemo    : '',
            accInfo     : '',
            accImage    : '',
            webSite     : '',
            repVideo    : [],
            choiceVideo : ''
        }
    };
}

export default factoryServiceModule;