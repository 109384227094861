<template>
    <ApprHeader />
    <FilterBox />
    <StikyTable :columns="columns" :rows="list" @row-click="getListClick"/>
    <ApprListPopup />
</template>

<script setup>
import ApprHeader from "@/views/common/ApprHeader.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import StikyTable from "@/components/table/StikyTable.vue";
import ApprListPopup from "@/views/center/approve/ApprListPopup.vue";

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const columns = [
    { key: 'id',        header: '가입 이메일', class: 'text-brand w17' },
    { key: 'cntry',     header: '국가', class: 'w10' },
    { key: 'area',      header: '지역', class: 'w10' },
    { key: 'ceNm',      header: '매장명', class: 'w13' },
    { key: 'ceoNm',     header: '대표자', class: 'w10' },
    { key: 'tel',       header: '휴대폰', class: 'w10' },
    { key: 'einNum',    header: '사업자등록증', class: 'w10' },
    { key: 'joinDt',    header: '가입 일시', class: 'w10' },
    { key: 'reqDt',     header: '심사요청 일시', class: 'w10' }
];

const list = computed(() => store.state.centerAppr.list);

/** 화면 호출 시 */
onMounted(() => {
    store.commit('popup/setStat', { name : 'centerAppr', stat : false });
    getList();
});

/** 리스트 호출 */
const getList = () => {
    store.dispatch('centerAppr/getList', { apprGb : 'J' });
}

/** 리스트 클릭 시 */
const getListClick = (data) => {
    store.dispatch('centerAppr/getInfo', { ceCd : data['ceCd'] });
    store.commit('popup/setStat', { name : 'centerAppr', stat : true });
}
</script>

<style scoped>

</style>