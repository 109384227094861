<template>
    <div class="relative block w-full">
        <TooltipLabel v-if="!hiddenLabel" :for="id" :label="label" :important="important" :useTooltip="useTooltip" :tooltipContent="tooltipContent"/>
        <div class="relative w-full">
            <input class="rounded w-full h-9 duration-200 border border-zinc-300 border-b-zinc-500 p-2.5 text-sm peer/focus disabled:border-zinc-200 disabled:border-b-zinc-200 disabled:bg-neutral-100" :type="type" :id="id" :name="name" :value="value" :placeholder="placeholder" :disabled="disabled" :readonly="readonly" />
            <span class="peer-focus/focus:bg-blue-600 w-full h-0.5 block absolute left-0 right-0 mx-auto bottom-0 duration-200 rounded-b"></span>
        </div>
    </div>
</template>

<script>
import TooltipLabel from "@/components/input/TooltipLabel.vue";

export default {
    name      : "LabelInput",
    components: {TooltipLabel},
    props     : {
        id            : String,
        type          : String,
        name          : String,
        value         : String,
        label         : String,
        placeholder   : String,
        important     : Boolean,
        useTooltip    : Boolean,
        tooltipContent: String,
        disabled      : Boolean,
        readonly      : Boolean,
        hiddenLabel   : Boolean,
    }
}
</script>

<style scoped>
</style>