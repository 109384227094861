import { createStore } from 'vuex';
import loginModule from '@/modules/loginModule';
import popupModule from '@/modules/popupModule';
import factoryApprModule from '@/modules/factory/factoryApprModule';
import centerApprModule from '@/modules/center/centerApprModule';
import factoryServiceModule from '@/modules/factory/factoryServiceModule';
import factoryNormalMemberModule from '@/modules/factory/factoryNormalMemberModule';
import factoryServiceMemberModule from '@/modules/factory/factoryServiceMemberModule';
import centerNormalMemberModule from '@/modules/center/centerNormalMemberModule';
import centerServiceModule from '@/modules/center/centerServiceModule';
import centerServiceMemberModule from '@/modules/center/centerServiceMemberModule';

const store = createStore({
    modules: {
        login                   : loginModule,
        popup                   : popupModule,
        factoryAppr             : factoryApprModule,
        factoryService          : factoryServiceModule,
        factoryNormalMember     : factoryNormalMemberModule,
        factoryServiceMember    : factoryServiceMemberModule,
        centerAppr              : centerApprModule,
        centerService           : centerServiceModule,
        centerNormalMember      : centerNormalMemberModule,
        centerServiceMember     : centerServiceMemberModule
    }
});

export default store;