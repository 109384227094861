<template>
    <PopupBox title="제휴회원" :tabs="popupTabs" showBlueButton blueButtonText="저장하기" :popNm="'factoryServiceMember'" @blueClick="getSaveConfirm">
        <template v-slot:header>
            <popupHead v-for="(tab, index) in popupTabs" :key="index" @click="getTabInfo(tab.value)"
                    :class="{ active : activeTab === tab.value }">
                {{ tab.title }}
            </popupHead>
        </template>
        <template v-slot:content>
            <popupBody v-if="activeTab === 1" class="space-y-4">
                <LabelRadio name="state" label="활동 / 정지 구분" useTooltip tooltipContent :options="activeOption" :checkedVal="info['tab1']['state']" 
                    @getRadio="getRadio" disabled/>
                <LabelInput type="text" label="활동상태" useTooltip tooltipContent="" :value="info['tab1']['state'] === 'Y' ? '활동' : '정지'" disabled />
                <LabelInput type="text" label="누적 명세표 건수"  useTooltip tooltipContent="" :value="info['tab1']['omCnt']" disabled />
                <ButtonInput type="text" label="연간 일반 매출" useToolTip tooltipContent :value="info['tab1']['saleCnt']" disabled buttonText="매출 원장" />
                <ButtonInput type="text" label="연간 일반 매입" useToolTip tooltipContent :value="info['tab1']['purcCnt']" disabled buttonText="매입 원장" />
                <ButtonInput type="text" label="서비스 결제" useToolTip tooltipContent disabled buttonText="결제 원장" />
                <LabelTextarea label="메모" useTooltip tooltipContent="" :value="info['tab1']['memo']" rows="5" @input="getMemo"/>
                <ModalTitle title="기본 회원 정보"/>
                <LabelInput type="text" label="최근 로그인" useTooltip tooltipContent="" :value="info['tab1']['lastDt']" disabled/>
                <LabelInput type="text" label="가입일시" useTooltip tooltipContent="" :value="info['tab1']['regDt']" disabled/>
                <LabelInput type="text" label="심사요청 일시" useTooltip tooltipContent="" :value="info['tab1']['apprDt']" disabled/>
                <LabelInput type="text" label="반려 횟수" useTooltip tooltipContent="" :value="info['tab1']['rejectCnt']" disabled/>
                <LabelTextarea label="반려 사유" useTooltip tooltipContent="" rows="2" :value="info['tab1']['rejectMemo']" disabled/>
                <ModalTitle title="기본 회원 정보"/>
                <LabelInput type="text" label="플랫폼 구분" useTooltip tooltipContent="" :value="info['tab1']['platForm']" disabled/>
                <LabelInput type="text" label="가입 채널" useTooltip tooltipContent="" :value="info['tab1']['channel']" disabled/>
                <LabelInput type="text" label="아이디 (이메일)" useTooltip tooltipContent="" :value="info['tab1']['id']" disabled/>
                <LabelInput type="text" label="이름" useTooltip tooltipContent="" :value="info['tab1']['name']" disabled/>
                <LabelInput type="text" label="연락처" useTooltip tooltipContent="" :value="info['tab1']['tel']" disabled/>
                <!-- 사업자등록증 파일 -->
                <LabelInput type="text" label="이용약관 동의" useTooltip tooltipContent="" :value="info['tab1']['agreeDt1']" disabled/>
                <LabelInput type="text" label="개인정보취급방침 동의" useTooltip tooltipContent="" :value="info['tab1']['agreeDt2']" disabled/>
                <ModalTitle title="공장 정보"/>
                <LabelInput type="text" label="공장시설 이름" useTooltip tooltipContent="" :value="info['tab1']['faNm']" disabled/>
                <LabelInput type="text" label="지역" useTooltip tooltipContent="" :value="info['tab1']['area']" disabled/>
                <LabelInput type="text" label="사업자등록번호" useTooltip tooltipContent="" :value="info['tab1']['einNum']" disabled/>
                <LabelInput type="text" label="사업자등록증상 대표자 이름" useTooltip tooltipContent="" :value="info['tab1']['ceoNm']" disabled/>
                <LabelInput type="text" label="주소" useTooltip tooltipContent="" :value="info['tab1']['addr']" disabled/>
                <LabelInput type="text" label="상세주소" useTooltip tooltipContent="" :value="info['tab1']['addrDetail']" disabled/>
                <LabelInput type="text" label="공장 연락처" useTooltip tooltipContent="" :value="info['tab1']['faTel']" disabled/>
                <ModalTitle title="결제 정보"></ModalTitle>
                <LabelInput type="text" label="대표 결제수단" useTooltip tooltipContent="" disabled/>
                <LabelInput type="text" label="결제 시작일" useTooltip tooltipContent="" disabled/>
                <LabelInput type="text" label="결제별칭" useTooltip tooltipContent="" disabled/>
            </popupBody>
            <popupBody v-if="activeTab === 2" class="space-y-4">
                <LabelInput type="text" label="제휴요청 일시" useTooltip tooltipContent="" :value="info['tab1']['reqDt']" disabled/>
                <LabelInput type="text" label="반려 횟수" useTooltip tooltipContent="" :value="info['tab1']['rejectCnt']" disabled/>
                <LabelTextarea label="반려 사유" useTooltip tooltipContent="" rows="2" :value="info['tab1']['rejectMemo']" disabled/>
                <ModalTitle title="공장시설 소개"/>
                <LabelInput type="text" label="공장시설 이름" useTooltip tooltipContent="" :value="info['tab2']['faNm']" disabled/>
                <LabelTextarea label="공장시설 소개" useTooltip tooltipContent="" :value="info['tab2']['faMemo']" rows="15" disabled/>
                <LabelTextarea label="공지사항 (선택)" useTooltip tooltipContent="" :value="info['tab2']['notice']" rows="2" disabled/>
                <LabelTextarea label="기타 (선택)" useTooltip tooltipContent="" :value="info['tab2']['etc']" rows="2" disabled/>
                <!-- 공장시설 소개 -->
                <ModalTitle title="공장시설 소개"/>
                <LabelImage label="대표사진" useTooltip tooltipContent="" :fileList="info['tab2']['repImage']"/>
                <LabelImage label="공장관련 사진" useTooltip tooltipContent="" :fileList="info['tab2']['faReImage']"/>
                <ModalTitle title="운영시간"/>
                <LabelTextarea label="운영시간" useTooltip tooltipContent="" :value="info['tab2']['workTime']" rows="5" disabled/>
                <LabelTextarea label="기타 (선택)" useTooltip tooltipContent="" :value="info['tab2']['workMemo']" rows="5" disabled/>
                <ModalTitle title="정산 계좌"/>
                <LabelInput type="text" label="정산 계좌" useTooltip tooltipContent="" :value="info['tab2']['accInfo']" disabled/>
                <LabelImage label="대표자 명의 통장사본" useTooltip tooltipContent="" :fileList="info['tab2']['accImage']" />
                <ModalTitle title="홍보 웹사이트"/>
                <LabelTextarea label="홍보 웹사이트" useTooltip tooltipContent="" :value="info['tab2']['webSite']" rows="5" disabled/>
                <ModalTitle title="미리보기 영상"/>
                <LabelMovie label="대표사진" useTooltip :fileList="info['tab2']['repVideo']" @getVideo="getVideo" />
            </popupBody>
            <popupBody v-if="activeTab === 3" class="space-y-8">
                <ModalTitle title="멤버 연동정보"/>
                <PopupTable :columns="memberList" :rows="info['tab3']['member']" />
                <ModalTitle title="매출 연동정보"/>
                <PopupTable :columns="saleList" :rows="info['tab3']['sale']" />
                <ModalTitle title="매입 연동정보"/>
                <PopupTable :columns="buyList" :rows="info['tab3']['purc']" />
            </popupBody>
        </template>
    </PopupBox>

    <ShowMovie v-if="videoPop" :fileNm="info['tab2']['choiceVideo']" @getClose="videoPop = false"/>
    <TextAlert title="활동변경" v-if="checkedValue === 'Y'" blueText="활동변경" whiteText="취소" @close="checkedValue=null" @get-result="getResult">
        <p class="text-sm">정지 해제를 하고 활동으로 변경하시겠습니까?</p>
    </TextAlert>
    <TextAlert title="정지" v-if="checkedValue === 'N'" @get-result="getStopConfirm" @close="checkedValue=null" blueText="정지하기" whiteText="취소">
        <div class="h-1"></div>
        <div class="flex space-x-2.5">
            <LabelInput hiddenLabel type="date" class="w-36" :value="stopInfo['startDt']" />
            <LabelInput hiddenLabel type="date" class="w-36" :value="stopInfo['endDt']" />
            <ButtonRadio :radioOptions="radioOptions" name="useYn" class="w-[15rem]" @getRadio="getDateSet"/>
        </div>
        <div class="h-1"></div>
        <LabelTextarea rows="4" label="반려사유" useTooltip tooltipContent="" :value="stopInfo['memo']" @input="getMngMemo"/>
    </TextAlert>
    <Confirm v-if="confirmData['confirmStat']" :type="confirmData['confirmType']" :title="confirmData['confirmTitle']" :description="confirmData['confirmDescription']" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script setup>
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import PopupBox from "@/components/modal/PopupBox.vue";
import PopupTable from "@/components/table/PopupTable.vue";
import LabelRadio from "@/components/input/LabelRadio.vue";
import ButtonInput from "@/components/input/ButtonInput.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import TextAlert from "@/components/modal/TextAlert.vue";
import LabelImage from "@/components/input/LabelImage.vue";
import LabelMovie from "@/components/input/LabelMovie.vue";
import ButtonRadio from "@/components/input/ButtonRadio.vue";
import ShowMovie from "@/components/modal/ShowMovie.vue";
import Confirm from "@/components/modal/Confirm.vue";

import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { getDate, getConvertDate } from '@/assets/js/function';

const store       = useStore();
const info        = computed(() => store.state.factoryServiceMember.info);
const activeTab   = computed(() => store.state.factoryServiceMember.tabGb);
const stopInfo    = computed(() => store.state.factoryServiceMember.stopInfo);
const videoPop    = ref(false);
const confirmData = reactive({
    confirmStat         : false,
    confirmType         : '',
    confirmTitle        : '',
    confirmDescription  : ''
});

const getConfirmResult = () => {
    confirmData['confirmStat'] = false;
    
    getResult();
};

const getConfirmClose = () => {
    confirmData['confirmStat'] = false;
};

const getConfirmSet = (type, title, description) => {
    confirmData['confirmType']        = type;
    confirmData['confirmStat']        = true;
    confirmData['confirmTitle']       = title;
    confirmData['confirmDescription'] = description;
};

const getStopConfirm = () => {
    getConfirmSet('confirm', '정지하기', '해당 계정을 정지하시겠습니까?');
}

const getSaveConfirm = () => {
    getConfirmSet('confirm', '저장하기', '저장하시겠습니까?');
}

const popupTabs   = [
    { title: '계정정보', value : 1 },
    { title: '제휴정보', value : 2 },
    { title: '연동정보', value : 3 }
];
const checkedValue = ref(null);
const activeOption = [
    { id: 'Y', value: 'Y', text: '활동' },
    { id: 'N', value: 'N', text: '정지' }
];
const memberList = [
    { key: 'email', header: '아이디', class: 'w28' },
    { key: 'nation', header: '신청일', class: 'w18' },
    { key: 'region', header: '최근 접속일', class: 'w18' },
    { key: 'fcNm', header: '접속횟수', class: 'w18' },
    { key: 'useYn', header: '관리자 정지', class: 'w18' }
];
const saleList = [
    { key: 'email', header: '연동 업체명', class: 'w28' },
    { key: 'nation', header: '연동일시', class: 'w18' },
    { key: 'region', header: '상태', class: 'w18' },
    { key: 'fcNm', header: '거래횟수', class: 'w18' },
    { key: 'useYn', header: '관리자 정지', class: 'w18' }
];
const buyList = [
    { key: 'email', header: '연동 업체명', class: 'w28' },
    { key: 'nation', header: '연동일시', class: 'w18' },
    { key: 'region', header: '상태', class: 'w18' },
    { key: 'fcNm', header: '거래횟수', class: 'w18' },
    { key: 'useYn', header: '관리자 정지', class: 'w18' }
];
const radioOptions = [
    { id: '7d', label: '7일', value : 7 },
    { id: '30d', label: '30일', value : 30 },
    { id: '60d', label: '60일', value : 60 },
    { id: 'eternity', label: '영구', value : 'INF' }
];

const getMemo = () => {
    info.value['tab1']['memo'] = event.target.value;
}

const getResult = () => {
    const data = {
        state : checkedValue.value ? checkedValue.value : info.value['tab1']['state'],
        memo  : info.value['tab1']['memo']
    };

    const result = store.dispatch('factoryServiceMember/getResult', data);

    result.then((res) => {
        if(res.code === 200)
        {
            getConfirmClose();
            checkedValue.value = null;
            store.dispatch('factoryServiceMember/getList', { state : 'Y' });
            store.commit('factoryServiceMember/setStopInfoReset');
            store.commit('popup/setStat', { name : 'factoryServiceMember', stat : false });
        }
        else
        {
            alert('데이터 처리에 실패하였습니다. 지속될 경우 담당자에게 문의하세요.');
        }
    });
}

const getTabInfo = (val) => {
    store.commit('factoryServiceMember/setTabGb', val);

    if(activeTab.value === 1)
    {
        store.dispatch('factoryServiceMember/getTabInfo1');
    }
    else if(activeTab.value === 2)
    {
        store.dispatch('factoryServiceMember/getTabInfo2');
    }
    else
    {
        store.dispatch('factoryServiceMember/getTabInfo3');
    }
}

const getRadio = (val) => {
    if(info.value['tab1']['state'] !== val)
    {
        checkedValue.value = val;
    }
}

const getDateSet = (val) => {
    stopInfo.value['choice'] = val;

    if(val === 'L')
    {
        stopInfo.value['startDt'] = '';
        stopInfo.value['endDt']   = '';
    }
    else
    {
        const date = getDate(val);

        stopInfo.value['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
        stopInfo.value['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');
    }
}

const getMngMemo = () => {
    stopInfo.value['memo'] = event.target.value;
}

const getVideo = (fileNm) => {
    videoPop.value = true;
    info.value['tab2']['choiceVideo'] = fileNm;
}
</script>

<style scoped>

</style>