<template>
    <div class="relative block w-full">
        <TooltipLabel :for="id" :label="label" :important="important" :useTooltip="useTooltip" :tooltipContent="tooltipContent"/>
        <div class="relative w-full cursor-pointer">
            <lanbel class="block rounded w-full h-9 duration-200 border border-zinc-300 border-b-zinc-500 cursor-pointer py-2.5 pl-2.5 py-5 text-sm" :for="fileId">{{ fileBtn }}</lanbel>
            <input class="hidden" :type="type" :id="fileId" :name="fileId" :placeholder="placeholder" />
            <FontAwesomeIcon :icon="['far', 'folder-open']" class="vertical-h absolute right-2.5" />
        </div>
    </div>
</template>

<script>
import TooltipLabel from "@/components/input/TooltipLabel.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "LabelFile",
    components: {FontAwesomeIcon, TooltipLabel},
    data() {
        return {
            selectedFileName: '',
        };
    },
    props     : {
        id            : String,
        type          : String,
        name          : String,
        label         : String,
        placeholder   : String,
        important     : Boolean,
        useTooltip    : Boolean,
        tooltipContent: String,
        fileId        : String,
        fileBtn       : String,
    }
}
</script>

<style scoped>

</style>