import { getAxiosData } from '@/assets/js/function';
import router from '@/router'

/**
 * @description 공장 일반회원 관련 vuex store module
 * @author 김원명, @date 2024-04-25
 */
const factoryServiceMemberModule = {
    namespaced : true,
    state: {
        faCd     : '',
        userCd   : '',
        tabGb    : 1,
        filters  : [
            { title: '활동회원', count: 0, value : 'Y' },
            { title: '정지회원', count: 0, value : 'N' },
            { title: '탈퇴회원', count: 0, value : 'C' }
        ],
        list     : [],
        info     : getInfo(),
        stopInfo : getStopInfo()
    },
    mutations: {
        setFaCd(state, faCd)
        {
            state.faCd = faCd
        },
        setUserCd(state, userCd)
        {
            state.userCd = userCd
        },
        setTabGb(state, gb)
        {
            state.tabGb = gb;
        },
        setFilters(state, filters)
        {
            state.filters = filters;
        },
        setList(state, list) 
        {
            state.list = list;
        },
        setTabInfo(state, { tabGb, info }) 
        {
            state.info[tabGb] = info;
        },
        setStopInfoReset(state)
        {
            state.stopInfo = getStopInfo();
        }
    },
    actions: {
        /** 리스트 호출 */
        async getList (context, data)
        {
            try
            {
                const res = await getAxiosData('https://po-data.plansys.kr/allianceFacList/getList', data);

                console.log(res);

                const list = [];

                res.data['list'].forEach((item) => {
                    list.push({
                        faCd    : item.faCd,
                        userCd  : item.userCd,
                        id      : item.email,
                        cntry   : '대한민국',
                        area    : item.area,
                        faNm    : item.faNm,
                        ceoNm   : item.ceoNm,
                        tel     : item.tel,
                        joinDt  : item.regDt,
                        loginDt : item.lastDt
                    })
                });

                context.commit('setList', list);

                const filters = [
                    { title: '활동회원', count: res.data['cnt'], value : 'Y' },
                    { title: '정지회원', count: res.data['stopCnt'], value : 'N' },
                    { title: '탈퇴회원', count: 0, value : 'C' }
                ];

                context.commit('setFilters', filters);
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상세 정보 호출 (계정정보 - tab 1) */
        async getTabInfo1 (context)
        {
            try
            {
                const params = {
                    faCd : context.state.faCd
                };
                const res    = await getAxiosData('https://po-data.plansys.kr/facList/getInfo', params);

                console.log(res);

                const info = {
                    state       : res.data['state'],
                    omCnt       : res.data['omCnt'] ? res.data['omCnt'] : 0,
                    saleCnt     : 0,
                    purcCnt     : 0,
                    memo        : res.data['userMemo'],
                    lastDt      : res.data['lastDt'] ? res.data['lastDt'] : '',
                    joinDt      : res.data['regDt'],
                    apprDt      : res.data['apprDt'],
                    rejectCnt   : res.data['rejectCnt'],
                    rejectMemo  : res.data['rejectMemo'],
                    platForm    : '플랜오더 매장',
                    channel     : res.data['channel'],
                    id          : res.data['id'],
                    name        : res.data['name'],
                    tel         : res.data['tel'],
                    agreeDt1    : res.data['agreeDt1'] ? res.data['agreeDt1'] : '',
                    agreeDt2    : res.data['agreeDt2'] ? res.data['agreeDt2'] : '',
                    faNm        : res.data['faNm'],
                    area        : res.data['area'],
                    einNum      : res.data['einNum'],
                    ceoNm       : res.data['ceoNm'],
                    addr        : res.data['addr'],
                    addrDetail  : res.data['addrDetail'],
                    faTel       : res.data['faTel']
                };

                context.commit('setTabInfo', { tabGb : 'tab1', info : info });
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상세 정보 호출 (제휴 정보 - tab 2) */
        async getTabInfo2 (context)
        {
            try
            {
                const params = {
                    faCd   : context.state.faCd
                };

                const res    = await getAxiosData('https://po-data.plansys.kr/allianceFacList/allianceInfo', params);
                console.log(res);

                const workData = res.data['workData'] !== '' ? JSON.parse(res.data['workData']) : '';
                let workTime = ``;

                if(workData !== '')
                {
                    workTime += `[월요일] ${workData['monSt']} ~ ${workData['monEd']}\n[화요일] ${workData['tueSt']} ~ ${workData['tueEd']}\n[수요일] ${workData['wedSt']} ~ ${workData['wedEd']}\n[목요일] ${workData['tueSt']} ~ ${workData['tueEd']}\n[금요일] ${workData['friSt']} ~ ${workData['friEd']}\n`;
                    workTime += `[공휴일] ${res.data['holiday'] === 'Y' ? '운영' : '휴무'}\n`;
                    workTime += `[휴무일] `;
                }

                const info   = {
                    faNm        : res.data['faNm'],
                    intro       : res.data['intro'],
                    notice      : res.data['notice'],
                    etc         : res.data['etc'],
                    repImage    : res.data['filePath'] ? [res.data['filePath']] : [],
                    faReImage   : res.data['faFileList'] ? [res.data['faFileList']] : [],
                    workTime    : workTime,
                    workMemo    : res.data['workMemo'],
                    accInfo     : res.data['bankNm']+' / '+res.data['accNm']+' / '+res.data['accNum'],
                    accImage    : res.data['accFilePath'] ? [res.data['accFilePath']] : [],
                    webSite     : '[홈페이지]'+res.data['homepage']+'\n[네이버 블로그]'+res.data['blog']+'\n[인스타그램]'+res.data['insta']+'\n[페이스북]'+res.data['facebook']+'\n[카카오]'+res.data['kakao'],
                    repVideo    : res.data['faMovieList'] ? res.data['faMovieList'] : [],
                    choiceVideo : ''
                };

                context.commit('setTabInfo', { tabGb : 'tab2', info : info });
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상세 정보 호출 (연동 정보 - tab 3) */
        async getTabInfo3 (context)
        {
            try
            {
                const list = [];

                context.commit('setTabInfo', { tabGb : 'tab3', info : list });
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상태 처리 및 저장 */
        async getResult (context, data)
        {
            try
            {
                const params = {
                    state    : data['state'],
                    userMemo : data['memo'],
                    stopDt   : data['state'] === 'N' ? context.state.stopInfo['choice'] : '',
                    periodSt : data['state'] === 'N' ? context.state.stopInfo['startDt'] : '',
                    periodEd : data['state'] === 'N' ? context.state.stopInfo['endDt'] : '',
                    mngMemo  : data['state'] === 'N' ? context.state.stopInfo['memo'] : '',
                    faCd     : context.state.faCd
                };

                console.log(params);

                await getAxiosData('https://po-data.plansys.kr/allianceFacList/saveInfo', params);

                return { code : 200, msg : 'success' };
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }

                return { code : 400, msg : 'fail' };
            }
        }
    }
};

function getInfo()
{
    return {
        tab1 : {
            state       : 'Y',
            omCnt       : 0,
            saleCnt     : 0,
            purcCnt     : 0,
            memo        : '',
            lastDt      : '',
            joinDt      : '',
            apprDt      : '',
            rejectCnt   : 0,
            rejectMemo  : '',
            platForm    : '플랜오더 매장',
            channel     : 'E',
            id          : '',
            name        : '',
            tel         : '',
            agreeDt1    : '',
            agreeDt2    : '',
            faNm        : '',
            area        : '',
            einNum      : '',
            ceoNm       : '',
            addr        : '',
            addrDetail  : '',
            faTel       : ''
        },
        tab2 : {
            faNm        : '',
            intro       : '',
            notice      : '',
            etc         : '',
            repImage    : [],
            faReImage   : [],
            workTime    : '',
            workMemo    : '',
            accInfo     : '',
            accImage    : '',
            webSite     : '',
            repVideo    : [],
            choiceVideo : ''
        },
        tab3 : {
            member : [],
            sale   : [],
            purc   : []
        }
    };
}

function getStopInfo()
{
    return {
        startDt : '',
        endDt   : '',
        choice  : 'INF',
        memo    : ''
    }
}

export default factoryServiceMemberModule;