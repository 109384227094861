<template>
    <div class="fixed top-0 left-0 w-full h-screen flex items-center z-30">
        <div class="relative p-6 w-[600px] rounded shadow mx-auto bg-white">
            <h3>{{ title }}</h3>
            <p>{{ description }}</p>
            <div class="flex space-x-4">
                <FullButton :buttonText="blueText" @click="getBlueClick" blueButton />
                <FullButton v-if="type === 'confirm' ? true : false" :buttonText="greyText" @click="getGreyClick" greyButton />
            </div>
        </div>
    </div>
</template>

<script>
import FullButton from "@/components/button/FullButton.vue";

export default {
    name : "ConfirmVue",
    components: {FullButton},
    props: {
        type       : String,
        title      : String,
        description: String,
        blueText   : String,
        greyText   : String,
    },
    methods : {
        getBlueClick() {
            this.$emit("getResult");
        },
        getGreyClick() {
            this.$emit("getClose");
        }
    }
}
</script>

<style scoped>

</style>