<template>
    <div class="p-7 pb-32 overflow-y-scroll scroll-none">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PopupBody"
}
</script>

<style scoped>
div{
    height: calc(100vh - 90px);
}
</style>