<template>
    <div class="z-30 fixed top-0 left-0 w-full h-screen flex items-center bg-black/60">
        <div class="relative p-8 mx-auto w-1/2">
            <video controls="controls" :src="path+fileNm" class="block"></video>
            <FontAwesomeIcon :icon="['fas', 'xmark']" class="text-white cursor-pointer absolute right-0 top-0 text-3xl" @click="getClose"/>
        </div>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits } from "vue";

defineProps({
    fileNm : String
});

const emit = defineEmits('getClose');

const getClose = () => {
    emit('getClose');
}

const path = 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com/';

</script>

<style scoped>

</style>