<template>
    <SectionHeader title="승인요청" :menuItems="menuItems" />
</template>

<script>
import SectionHeader from "@/components/common/SectionHeader.vue";

export default {
    name: "ApprHeader",
    components: {SectionHeader},
    data(){
        return {
            menuItems: [
                {to: "/factory/apprList", label: "공장 가입요청"},
                {to: "/center/apprList", label: "매장 가입요청"},
                {to: "/factory/allianceApprList", label: "공장 제휴요청"},
                {to: "/center/allianceApprList", label: "매장 제휴요청"},
            ]
        }
    }
}
</script>

<style scoped>

</style>