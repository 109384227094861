<template>
    <div class="relative block w-full">
        <TooltipLabel v-if="!hiddenLabel" :for="id" :label="label" :important="important" :useTooltip="useTooltip" :tooltipContent="tooltipContent"/>
        <div class="relative w-full">
            <textarea class="rounded w-full duration-200 border border-zinc-300 border-b-zinc-500 p-2.5 text-sm peer/focus disabled:border-zinc-200 disabled:border-b-zinc-200 disabled:bg-neutral-100 resize-none" :id="id" :name="name" :value="value" :placeholder="placeholder" :disabled="disabled" :readonly="readonly" :rows="rows" />
            <span class="peer-focus/focus:bg-blue-600 w-full h-0.5 block absolute left-0 right-0 mx-auto bottom-[7px] duration-200 rounded-b"></span>
        </div>
    </div>
</template>

<script>
import TooltipLabel from "@/components/input/TooltipLabel.vue";

export default {
    name: "LabelTextarea",
    components: {TooltipLabel},
    props     : {
        id            : String,
        name          : String,
        value         : String,
        label         : String,
        placeholder   : String,
        rows          : String,
        important     : Boolean,
        useTooltip    : Boolean,
        tooltipContent: String,
        disabled      : Boolean,
        readonly      : Boolean,
        hiddenLabel   : Boolean,
    }
}
</script>

<style scoped>

</style>