<template>
    <ModalBox title="공장 회원가입 1/2">
        <div class="space-y-5">
            <LabelInput type="email" id="id" name="id" label="아이디 (이메일)" placeholder="이메일을 입력해주세요" useTooltip tooltipContent="" />
            <LabelInput type="password" id="pw" name="pw" label="비밀번호" placeholder="비밀번호를 입력해주세요." useTooltip tooltipContent="" />
            <LabelInput type="password" id="pwChk" name="pwChk" label="비밀번호 확인" placeholder="비밀번호를 다시 한 번 입력해주세요." useTooltip tooltipContent="" />
            <LabelInput type="text" id="name" name="name" label="이름" placeholder="이름을 입력해주세요." useTooltip tooltipContent="" />
            <ButtonInput type="number" id="" name="" label="연락처" placeholder="-를 제외하고 숫자만 입력해주세요." useTooltip tooltipContent="" buttonText="본인 인증" />
            <LabelFile id="" name="" label="사업자등록증 업로드" placeholder="파일을 첨부해주세요." useTooltip tooltipContent="" />
        </div>
        <FullButton buttonText="다음" greyButton />
    </ModalBox>
</template>

<script>
import ModalBox from "@/components/modal/ModalBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import ButtonInput from "@/components/input/ButtonInput.vue";
import LabelFile from "@/components/input/LabelFile.vue";
import FullButton from "@/components/button/FullButton.vue";

export default {
    name: "FactoryJoinFirst",
    components: {FullButton, LabelFile, ButtonInput, LabelInput, ModalBox}
}
</script>

<style scoped>

</style>