import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const jwt = store.state.login.jwt;

if(jwt === null)
{
    store.dispatch('login/initializeState');
}

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')