<template>
    <div class="relative block w-full">
        <TooltipLabel v-if="!hiddenLabel" :for="id" :label="label" :important="important" :useTooltip="useTooltip"
                      :tooltipContent="tooltipContent"/>
        <div class="relative w-full flex p-3 rounded border border-zinc-200 bg-neutral-100 space-x-1">
            <div v-for="(item, index) in fileList" :key="index" class="m-1.5 rounded w-16 h-36 overflow-hidden bg-zinc-100 relative shadow">
                <video :src="path+item" class="h-full" height="144" />
                <FontAwesomeIcon :icon="['fas', 'play']" class="absolute mx-auto vertical-h text-white left-0 right-0 cursor-pointer shadow" @click="getVideo(item)"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import TooltipLabel from "@/components/input/TooltipLabel.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { defineProps, defineEmits } from "vue";

const path = 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com/';

defineProps({
    hiddenLabel   : Boolean,
    id            : String,
    label         : String,
    important     : Boolean,
    useTooltip    : Boolean,
    tooltipContent: String,
    fileList      : Array
});

const emit = defineEmits('getVideo');

const getVideo = (fileNm) => {
    emit('getVideo', fileNm);
}
</script>

<style scoped>

</style>